import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { menu } from "src/app/tools/button-menu/button-menu.component";
import {
  InvoiceService,
  Invoice,
  invoiceDetail,
  logType,
  invoiceLog,
} from "../services/invoice.service";
import { ModalService } from "projects/cloud-components/src/lib/modal/modal.service";
import {
  ClModalDialogService,
  clPrompt,
} from "projects/cloud-components/src/lib/cl-modal-dialog/cl-modal-dialog.service";
import { CloudLoginService } from "cloud-labs-core";
import { AppUsersService } from "src/app/settings/services/app-users.service";
import { AppPermissionsService } from "src/app/settings/services/app-permissions.service";
import { confirm } from "devextreme/ui/dialog";
import { GlobalsService } from "src/app/services/globals.service";
import { DxDataGridComponent } from "devextreme-angular";
import moment from "moment";
import notify from "devextreme/ui/notify";
import { Branch } from "../models/global-model";
import { saveAs } from "file-saver";

@Component({
  selector: "app-e-invoice-inbound",
  templateUrl: "./e-invoice-inbound.component.html",
  styleUrls: ["./e-invoice-inbound.component.scss"],
})
export class EInvoiceInboundComponent implements OnInit {
  now: Date = new Date();

  startDate: Date = new Date();
  endDate: Date = new Date();

  activeInvoice: Invoice = new Invoice();
  invoiceMenu: menu[] = [];
  inoviceError = "";
  popupVisible = false;
  selectedRows: number[] = [];
  loadingVisible = false;
  loadIndicatorVisible = false;
  invoiceSendEmailAddress: string = "";
  filterStatus = "standby";
  filterDocumentType = "eInvoice";
  animationState = "in";
  detail: invoiceDetail = new invoiceDetail();
  loadingMessage = "Yükleniyor...";
  activeFilter = {};
  backofficeTransferPopupVisible: boolean = false;
  selectedInovices: Invoice[] = [];
  typeList: any;
  approveVisble = false;
  rejectVisible = false;
  arDescription = "";
  public theBoundCallback: Function;
  constructor(
    public inoviceSrc: InvoiceService,
    private dialog: ClModalDialogService,
    private modalService: ModalService,
    private ls: CloudLoginService,
    public appUserService: AppUsersService,
    public perm: AppPermissionsService,
    private elementref: ElementRef,
    private glb: GlobalsService
  ) {
    this.typeList = [
      { id: 0, name: "Stok" },
      { id: 1, name: "Hizmet" },
    ];
  }

  ngOnInit() {
    this.invoiceMenu.push({
      title: "Okundu?",
      faicon: "fa-file-invoice",
      icon: "money",
      tag: "readed",
      selectable: false,
      disabled: false,
    });
    this.invoiceMenu.push({
      title: "Stok?",
      faicon: "fa-file-invoice",
      icon: "money",
      tag: "stock",
      selectable: false,
      disabled: false,
    });

    this.invoiceMenu.push({
      title: "Önizleme",
      faicon: "fa-file-invoice",
      icon: "money",
      tag: "preview",
      selectable: false,
      disabled: true,
    });

    this.invoiceMenu.push({
      title: "İrsaliye Fatura Dönüştür",
      faicon: "fa-file-invoice",
      icon: "money",
      tag: "convertInvoice",
      selectable: false,
      disabled: false,
    });

    this.invoiceMenu.push({
      title: "BackOffice Aktar",
      faicon: "fa-sitemap",
      icon: "money",
      tag: "SendBackOffice",
      selectable: false,
      disabled: false,
    });

    this.invoiceMenu.push({
      title: "EPosta Gönder",
      faicon: "fa-envelope-open-text",
      icon: "card_giftcard",
      tag: "sendmail",
      selectable: false,
      disabled: true,
    });
    this.invoiceMenu.push({
      title: "Fatura Ayrıntısı",
      faicon: "fa-list-alt",
      icon: "card_giftcard",
      tag: "detail",
      selectable: false,
      disabled: true,
    });
    // this.invoiceMenu.push({
    //   title: "Excele Aktar",
    //   faicon: "fa-file-excel",
    //   icon: "card_giftcard",
    //   tag: "emp",
    //   selectable: false,
    //   disabled: true,
    // });

    this.invoiceMenu.push({
      title: "İptal",
      faicon: "fa-trash-alt",
      icon: "card_giftcard",
      tag: "cancel",
      selectable: false,
      disabled: false,
    });

    this.invoiceMenu.push({
      title: "Onayla",
      faicon: "fa-sitemap",
      icon: "card_giftcard",
      tag: "approve",
      selectable: false,
      disabled: false,
    });
    this.invoiceMenu.push({
      title: "Reddet",
      faicon: "fa-sitemap",
      icon: "card_giftcard",
      tag: "reject",
      selectable: false,
      disabled: false,
    });

    //this.invoiceMenu.push({ title: "ERP Aktar", faicon: "fa-sitemap", icon: "card_giftcard", tag: "erp", selectable: false, disabled: true })
    this.invoiceMenu.push({
      title: "Loglar",
      faicon: "fa-clipboard-list",
      icon: "card_giftcard",
      tag: "logs",
      selectable: false,
      disabled: false,
    });

    this.invoiceMenu.push({
      title: "Yenile",
      faicon: "fa-clipboard-list",
      icon: "card_giftcard",
      tag: "refresh",
      selectable: false,
      disabled: false,
    });

    this.elementref.nativeElement.style.setProperty(
      "--button-menu-width",
      "50px"
    );
    this.elementref.nativeElement.style.setProperty(
      "--button-menu-height",
      "60px"
    );

    this.startDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.endDate = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);

    this.theBoundCallback = this.theCallback.bind(this);
  }

  getBrancList() {
    // this.sponsorService.getList().subscribe((res: Sponsor[]) => {
    this.inoviceSrc.getBranchList().subscribe((b: Branch[]) => {
      this.inoviceSrc.branchList = b;
    });
  }
  public theCallback() {
    this.backofficeTransferPopupVisible = false;
  }

  calculateCellValue(data) {
    return data.grandTotal - data.taxTotal;
  }

  ngAfterViewInit() {
    this.getBrancList();
    this.inoviceSrc.data = [];
    this.loadData();
  }

  currencies = [
    {
      id: "try",
      name: "TRY",
    },
    {
      id: "usd",
      name: "USD",
    },
    {
      id: "eur",
      name: "EUR",
    },
  ];

  cancel() {
    const result = confirm(
      "Gelen E faturalar portal üzerinden iptal edilemez.<br> Bu işlem sadece haricen iptal edilmiş bir faturanın portal üzerindeki durumunu değiştirmenize ve iptal sebebi girmenizi sağlayacaktır.<br> işleme devam etmek istiyormusunuz?",
      "Dikkat"
    );
    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.dialog.title = "Faturayı İptal et";
        this.dialog.message = "Bu Fatura İptal Edilecek, Onaylıyor musunuz?";
        this.dialog.openWithPompt([
          { title: "Neden İptal Ediliyor?", value: "", required: true },
        ]);
        this.dialog.statPrompt().then((promptsResp) => {
          if (promptsResp.stat) {
            this.loadingVisible = true;
            this.activeInvoice = this.selectedInovices[0];
            this.activeInvoice.status = "cancelled";
            this.addLog(
              "cancel",
              this.activeInvoice,
              "İptal Edildi",
              Object.assign(promptsResp).prompts[0].value
            );

            this.activeInvoice.description =
              Object.assign(promptsResp).prompts[0].value;
            this.inoviceSrc.cancel(this.activeInvoice.uuid).subscribe(
              (o) => {
                this.inoviceSrc.upsert(this.activeInvoice);
                this.refreshWithFilter();
                this.inoviceSrc.runCalculateStatics();

                this.updateLocalInvoiceObjects(Object.assign(o));
              },
              (e) => {
                this.ShowError(e.error.message);
              }
            );
          }
        });
      } else {
        //this.glb.pushToast("İptal Edildi.", "error", 2000)
      }
    });
  }

  backCancel() {
    const result = confirm(
      "İptal Edilen Faturada İşlemi Geri Almak İstiyor musunuz?",
      "Dikkat"
    );
    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.dialog.title = "İptal Geri Al";
        this.dialog.message =
          "İptal Edilen Fatura Geri Alınıyor, Onaylıyor musunuz?";
        this.dialog.openWithPompt([
          { title: "Neden Geri Alınıyor?", value: "", required: true },
        ]);
        this.dialog.statPrompt().then((promptsResp) => {
          if (promptsResp.stat) {
            this.loadingVisible = true;

            this.activeInvoice = this.selectedInovices[0];
            this.activeInvoice.status = "standby";
            this.activeInvoice.description =
              Object.assign(promptsResp).prompts[0].value;
            this.inoviceSrc.upsert(this.activeInvoice);
            this.refreshWithFilter();
            this.inoviceSrc.runCalculateStatics();
            this.addLog(
              "log",
              this.activeInvoice,
              "İptal Geri Alındı",
              Object.assign(promptsResp).prompts[0].value
            );
          }
        });
      } else {
        //this.glb.pushToast("İptal Edildi.", "error", 2000)
      }
    });
  }

  loadData() {
    this.activeFilter = {
      $and: [
        { documentType: "eInvoice" },
        { direction: "inBound" },
        {
          $and: [
            { invoiceDate: { $gte: this.startDate.toISOString() } },
            { invoiceDate: { $lte: this.endDate.toISOString() } },
          ],
        },
      ],
    };

    this.refreshWithFilter();
  }

  /*   loadData() {
      let x = { sendDate: { $gte: { $dateFromString: this.startDate.toISOString() } } }
      let filter = {
        $and: [
          { direction: 'inBound' },
          { typeScenario: 'base' }
        ]
      }

      this.loadingVisible = true
      let o = { status: this.filterStatus }
      this.inoviceSrc.load(filter).then(o => {
        this.loadingVisible = false

        this.inoviceSrc.data =
      })
    } */

  selectionChangedHandler(e) {
    if (e.selectedRowsData.length == 0) return;
    // this.deSelectSingleRow(e.currentSelectedRowKeys);
    this.selectedInovices = e.selectedRowsData;
    this.activeInvoice = e.selectedRowsData[0];

    this.invoiceMenu.find((f) => f.tag == "preview").disabled =
      this.selectedInovices[0].status != "success" &&
      this.selectedInovices[0].status != "standby";
    this.invoiceMenu.find((f) => f.tag == "detail").disabled =
      this.selectedRows.length > 1 ||
      this.selectedInovices[0].status != "success";
    this.invoiceMenu.find((f) => f.tag == "sendmail").disabled =
      this.selectedInovices[0].status != "success";

    this.invoiceMenu.find((f) => f.tag == "reject").disabled = false;
    this.invoiceMenu.find((f) => f.tag == "approve").disabled = false;

    if (this.activeInvoice.typeScenario != "merchant") {
      this.invoiceMenu.find((f) => f.tag == "reject").disabled = true;
      this.invoiceMenu.find((f) => f.tag == "approve").disabled = true;
    }

    if (this.selectedRows.length > 1) {
      this.invoiceMenu.find((f) => f.tag == "reject").disabled = true;
      this.invoiceMenu.find((f) => f.tag == "approve").disabled = true;
      this.invoiceMenu.find((f) => f.tag == "convertInvoice").disabled = true;
      this.invoiceMenu.find((f) => f.tag == "cancel").disabled = true;
      if (this.selectedRows.length == 1) {
        this.invoiceMenu.find((f) => f.tag == "SendBackOffice").disabled =
          false;
      } else {
        this.invoiceMenu.find((f) => f.tag == "SendBackOffice").disabled = true;
      }
    } else {
      this.invoiceMenu.find((f) => f.tag == "convertInvoice").disabled = false;
      this.invoiceMenu.find((f) => f.tag == "SendBackOffice").disabled = false;
      if (this.activeInvoice.merchantStatus == "") {
        this.invoiceMenu.find((f) => f.tag == "reject").disabled = false;
        this.invoiceMenu.find((f) => f.tag == "approve").disabled = false;
        this.invoiceMenu.find((f) => f.tag == "cancel").disabled = false;
      } else {
        this.invoiceMenu.find((f) => f.tag == "reject").disabled = true;
        this.invoiceMenu.find((f) => f.tag == "approve").disabled = true;
      }
      if (this.activeInvoice.status == "cancelled") {
        this.invoiceMenu.find((f) => f.tag == "cancel").title = "İptal Geri Al";
      } else {
        this.invoiceMenu.find((f) => f.tag == "cancel").title = "İptal";
      }
    }

    if (this.activeInvoice.typeScenario == "base") {
      this.invoiceMenu.find((f) => f.tag == "approve").disabled = true;
      this.invoiceMenu.find((f) => f.tag == "reject").disabled = true;
    } else {
      this.invoiceMenu.find((f) => f.tag == "reject").disabled = false;
      this.invoiceMenu.find((f) => f.tag == "approve").disabled = false;
    }
  }

  inovice_menu_click(e) {
    console.log(e);

    if (e.tag == "preview") {
      if (this.selectedInovices.length > 20) {
        this.glb.notice(
          "Bir defa da en çok 20 faturada işlem yapılabilir.",
          "warning"
        );
        return;
      }
      this.previewInvoice();
    }

    if (e.tag == "erp") {
      if (this.selectedInovices.length > 20) {
        this.glb.notice(
          "Bir defa da en çok 20 faturada işlem yapılabilir.",
          "warning"
        );
        return;
      }
      this.sendErp();
    }

    if (e.tag == "SendBackOffice") {
      if (this.selectedRows.length == 0) {
        this.glb.notice("Lütfen Fatura Seçiniz.", "warning");
        return;
      }
      this.SendBackOffice();
    }

    if (e.tag == "cancel") {
      if (this.activeInvoice.status != "cancelled") {
        this.cancel();
      } else {
        this.backCancel();
      }
    }

    if (e.tag == "sendmail") {
      if (this.selectedInovices.length > 20) {
        this.glb.notice(
          "Bir defa da en çok 20 faturada işlem yapılabilir.",
          "warning"
        );
        return;
      }
      this.senMailInvoice();
    }

    if (e.tag == "detail") {
      this.detailInvoice();
    }

    if (e.tag == "logs") {
      this.modalService.open("invoiceLogsModal");
    }

    if (e.tag == "refresh") {
      this.inoviceSrc.runCalculateStatics();
      this.refreshWithFilter();
    }

    if (e.tag == "readed") {
      if (this.selectedInovices.length > 1) {
        const result = confirm(
          this.selectedInovices.length +
            " adet kayıt seçtiniz.İşleme devam etmek istiyor musunuz?",
          "Okundu"
        );
        result.then((dialogResult: any) => {
          if (dialogResult) {
            this.selectedRows = [];
            this.selectedInovices.forEach((el) => {
              el.readed = el.readed ? false : true;
              this.inoviceSrc.upsert(el);
              this.addLog(
                "log",
                el,
                "okundu",
                el.readed ? "işaretlendi" : "kaldırıldı"
              );
            });
          } else {
            //this.glb.pushToast("İptal Edildi.", "error", 2000)
          }
        });
      } else {
        this.selectedInovices.forEach((el) => {
          if (el.readed == true) {
            const result = confirm(
              this.selectedInovices[0].invoiceNumber +
                " numaralı faturanın okundu bilgisini kaldırmak istediğinize emin misiniz?",
              "Okundu"
            );
            result.then((dialogResult: any) => {
              if (dialogResult) {
                this.selectedInovices.forEach((el) => {
                  el.readed = el.readed ? false : true;
                  this.inoviceSrc.upsert(el);
                  this.addLog(
                    "log",
                    el,
                    "okundu",
                    el.readed ? "işaretlendi" : "kaldırıldı"
                  );
                });
                this.selectedRows = [];
              } else {
                //this.glb.pushToast("İptal Edildi.", "error", 2000)
              }
            });
          } else {
            this.selectedInovices.forEach((el) => {
              el.readed = el.readed ? false : true;
              this.inoviceSrc.upsert(el);
              this.addLog(
                "log",
                el,
                "okundu",
                el.readed ? "işaretlendi" : "kaldırıldı"
              );
            });
            this.selectedRows = [];
          }
        });
      }
    }

    if (e.tag == "stock") {
      if (this.selectedInovices.length > 1) {
        const result = confirm(
          this.selectedInovices.length +
            " adet kayıt seçtiniz.İşleme devam etmek istiyor musunuz?",
          "Stok"
        );
        result.then((dialogResult: any) => {
          if (dialogResult) {
            this.selectedRows = [];
            this.selectedInovices.forEach((el) => {
              el.stock = el.stock ? false : true;
              this.inoviceSrc.upsert(el);
              this.addLog(
                "log",
                el,
                "stok",
                el.stock ? "İşaretlendi" : "kaldırıldı"
              );
            });
          } else {
          }
        });
      } else {
        this.selectedInovices.forEach((el) => {
          if (el.stock == true) {
            const result = confirm(
              this.selectedInovices[0].invoiceNumber +
                " numaralı faturanın stok bilgisini kaldırmak istediğinize emin misiniz?",
              "Stok"
            );
            result.then((dialogResult: any) => {
              if (dialogResult) {
                this.selectedRows = [];
                this.selectedInovices.forEach((el) => {
                  el.stock = el.stock ? false : true;
                  this.inoviceSrc.upsert(el);
                  this.addLog(
                    "log",
                    el,
                    "stok",
                    el.stock ? "İşaretlendi" : "kaldırıldı"
                  );
                });
              } else {
              }
            });
          } else {
            this.selectedInovices.forEach((el) => {
              el.stock = el.stock ? false : true;
              this.inoviceSrc.upsert(el);
              this.addLog(
                "log",
                el,
                "stok",
                el.stock ? "İşaretlendi" : "kaldırıldı"
              );
            });
            this.selectedRows = [];
          }
        });
      }
    }

    if (e.tag == "approve") {
      // this.approveVisble = true;
      this.approve();
    }

    if (e.tag == "reject") {
      // this.rejectVisible = true;
      this.reject();
    }

    if (e.tag == "convertInvoice") {
      if (this.selectedInovices[0].stock == true) {
        this.glb.notice(
          "Belgeyi faturalaştırmak için stok durumunu kaldırınız!",
          "warning"
        );
        return;
      }
      this.activeInvoice = this.selectedInovices[0];
      this.modalService.open("convertInvoiceModal");
    }
  }

  sendMail(isValid, mail) {
    if (!isValid) return;
    this.modalService.close("invoiceEmail");
    this.selectedInovices.forEach((el) => {
      this.loadingVisible = true;
      this.inoviceSrc.mail(el.uuid, mail).subscribe(
        (o) => {
          this.addLog("log", el, "invoice-mail-send", mail);
          this.loadingVisible = false;
          let resInvoice: Invoice = Object.assign(o);
          let pobj = this.inoviceSrc.data.findIndex(
            (X) => X.uuid == resInvoice.uuid
          );
          el = Object.assign(o);
          this.inoviceSrc.data[pobj] = Object.assign(o);
        },
        (e) => {
          this.loadingVisible = false;
          this.ShowError(e.error.message);
        }
      );
    });
  }

  saveFatura(id: string) {
    let iframe = document.querySelector(
      "#" + id + " iframe"
    ) as HTMLIFrameElement;

    if (iframe) {
      saveAs(iframe.src, `invoice-${new Date().toISOString()}.pdf`);
    }
  }

  async printFatura(id: string) {
    let iframe = document.querySelector(
      "#" + id + " iframe"
    ) as HTMLIFrameElement;
    console.log("#" + id + " iframe");
    if (iframe) {
      iframe.contentWindow.print();
      try {
        this.loadingVisible = true;
        const promises = [];
        this.selectedInovices.forEach((elm) => {
          elm.printed = true;
          promises.push(
            this.inoviceSrc
              .saveInvoice({
                ...elm,
                printed: true,
              })
              .toPromise()
          );
        });
        await Promise.allSettled(promises);
        this.loadingVisible = false;
      } catch (error) {
        console.log(error);
        this.loadingVisible = false;
      } finally {
        this.loadingVisible = false;
      }
    }
  }

  sendMailFromPreview() {
    this.modalService.close("inoviceModal");
    setTimeout(() => {
      this.modalService.open("invoiceEmail");
    }, 500);
  }

  previewDX = false;
  private previewInvoice() {
    this.loadingVisible = true;
    let uuids = [];
    this.activeInvoice = this.selectedInovices[0];

    for (const iterator of this.selectedInovices) {
      uuids.push(iterator.uuid);
    }

    this.inoviceSrc.requestPdfInvoices(uuids).subscribe(
      (o: any) => {
        this.loadingVisible = false;
        this.modalService.open("inoviceModal");
        const byteArray = new Uint8Array(
          atob(o.pdf)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        const file = new Blob([byteArray], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        document.getElementById(
          "invHtml"
        ).innerHTML = `<iframe src='${fileURL}#toolbar=0' type="application/pdf" width="100%" height="600px" />`;
        this.addLog(
          "log",
          this.selectedInovices[0],
          "fatura-ön-izleme",
          "başarılı"
        );
      },
      (e) => {
        this.ShowError(e.error.message);
      }
    );

    /*
    this.loadingVisible = true
    this.activeInvoice = this.selectedInovices[0];
    this.inoviceSrc.preview(this.activeInvoice.uuid).subscribe(o => {
      this.loadingVisible = false
      this.modalService.open("inoviceModal");
      document.getElementById('invHtml').innerHTML = `<embed src='data:application/pdf;base64,${Object.assign(o).pdf}' type="application/pdf" width="100%" height="600px" />`

      this.addLog('log', this.selectedInovices[0], "eInvoice-preview", "success")
    }, e => {
      console.log(e)
      this.ShowError(e.error.message)
    }); */
  }

  private senMailInvoice() {
    this.modalService.open("invoiceEmail");
  }

  private detailInvoice() {
    this.loadingVisible = true;
    this.activeInvoice = this.selectedInovices[0];
    let uuid = this.selectedInovices[0].uuid;
    this.inoviceSrc.detail(uuid).subscribe(
      (o) => {
        console.log("Fatura :", o);
        this.loadingVisible = false;
        this.detail = Object.assign(o);
        this.modalService.open("inoviceDetailModal");
      },
      (e) => {
        this.ShowError(e.error.message);
      }
    );
  }

  backOfficeModalVisible = false;
  SendBackOffice() {
    this.backOfficeModalVisible = true;
    this.activeInvoice = this.selectedInovices[0];
    let modalRef = this.modalService.open("backOfficeModal");
    console.log("", modalRef);
    modalRef.onDidMiss().then((res) => {
      this.backOfficeModalVisible = false;
    });
  }

  private sendErp() {
    // if (this.selectedInovices.length > 20) {
    //   this.glb.notice('Bir defa da en çok 20 faturada işlem yapılabilir.', 'warning')
    //   return
    // }
    this.selectedRows = [];

    this.loadingVisible = true;
    let erpsender = new Promise((resolve, reject) => {
      this.selectedInovices.forEach((el, index, array) => {
        this.inoviceSrc.sendInvoiceToErp(el.uuid).subscribe(
          (o) => {
            this.loadingMessage = "Erp'ye gönderiliyor (" + index + ")";
            let ps = Object.assign(o);

            if (ps.message == "success") {
              if (el.erp) el["erp"] = "new";
              el.erp = "success";
              this.addLog("erp", el, "integration", "success");
            } else {
              this.addLog("erp", el, "integration-error", ps.message);
            }
            if (index === array.length - 1) resolve("");
          },
          (e) => {
            if (index === array.length - 1) reject(e.error.message);
            //this.ShowError(e.error.message)

            this.addLog("erp", el, "integration-error", e.error.message);
          }
        );
      });
    });

    erpsender
      .then((o) => {
        this.inoviceSrc.runCalculateStatics();
        this.loadingVisible = false;
        this.loadingMessage = "Yükleniyor...";
      })
      .catch((p) => {
        this.loadingVisible = false;
        this.ShowError(p);
        this.loadingMessage = "Yükleniyor...";
      });
  }

  private ShowError(e) {
    console.log(e);
    this.loadingVisible = false;
    this.inoviceError = e;
    this.modalService.open("inoviceError");
  }

  updateLocalInvoiceObjects(respInvObj: Invoice, visible: boolean = false) {
    this.loadingVisible = visible;
    this.activeInvoice = respInvObj;
    let dataIndex = this.inoviceSrc.data.findIndex(
      (X) => X.uuid == this.activeInvoice.uuid
    );
    this.inoviceSrc.data[dataIndex] = respInvObj;
  }

  addLog(lType: logType, inv: Invoice, title, message) {
    let log: invoiceLog = new invoiceLog();
    log.type = lType;
    log.title = title;
    log.message = message;
    log.userId = this.ls.userInfo.id;
    log.uuid = inv.uuid;
    if (!inv.logs) {
      inv["logs"] = [];
    }
    inv.logs.push(log);
    this.inoviceSrc.addInvoiceLog(log).subscribe((x: any) => {
      console.log("addlog", x);
    });
  }

  closeModal(i) {
    document.getElementById("invHtml").innerHTML = "";
    this.modalService.close(i);
  }

  refresh() {
    this.inoviceSrc.refresh();
  }

  eInvoicePos = true;
  eInvoiceErp = false;
  eArchivePos = false;
  eArchiveErp = false;
  showStatusMenu = false;
  activeStat = { docType: "", status: "", source: "" };

  statMenu() {
    this.showStatusMenu = this.showStatusMenu ? false : true;
    this.inoviceSrc.runCalculateStatics();
  }

  loadGrid(item) {
    if (item.count == 0) return;
    this.activeFilter = {
      $and: [{ direction: "inBound" }, { typeScenario: item.id }],
    };
    console.log(this.activeFilter);

    this.activeStat = {
      docType: item.docType,
      status: item.id,
      source: item.source,
    };

    this.refreshWithFilter();
  }

  refreshWithFilter() {
    this.loadingVisible = true;
    this.inoviceSrc.load(this.activeFilter).then((o) => {
      this.loadingVisible = false;
      console.log(o);
    });
  }

  // addLog(lType: logType, inv: Invoice, title, message) {
  //   let log: invoiceLog = new invoiceLog();
  //   log.type = lType;
  //   log.title = title;
  //   log.message = message;
  //   log.userId = this.ls.userInfo.id;
  //   if (!inv.logs) {
  //     inv["logs"] = [];
  //   }
  //   inv.logs.push(log);
  //   this.inoviceSrc.upsert(inv);
  // }

  getCss(item) {
    if (item.count == 0) {
      return "gray-stat";
    } else {
      if (
        this.activeStat.docType == item.docType &&
        this.activeStat.status == item.id &&
        this.activeStat.source == item.source
      ) {
        return item.id + " active-stat";
      } else {
        return item.id;
      }
    }
  }

  approve() {
    this.dialog.title = "Faturayı Onayla";
    this.dialog.message = "Faturayı Onaylaymak İstiyor musunuz?";

    this.dialog.openWithPompt([
      { title: "Açıklama Giriniz", value: "", required: true },
    ]);
    this.dialog.statPrompt().then((promptsResp) => {
      if (promptsResp.stat) {
        this.loadingVisible = true;
        this.loadingMessage = "İşleniyor...";
        this.activeInvoice = this.selectedInovices[0];
        // this.activeInvoice.merchantDesc = Object.assign(promptsResp).prompts[0].value
        console.log(Object.assign(promptsResp).prompts[0].value);

        this.inoviceSrc
          .approveInbond(
            this.activeInvoice.uuid,
            Object.assign(promptsResp).prompts[0].value
          )
          .toPromise()
          .then(
            (o) => {
              this.refreshWithFilter();
              this.dialog.close();
              this.loadingVisible = false;
              this.addLog(
                "approve",
                this.activeInvoice,
                "fatura onay",
                Object.assign(promptsResp).prompts[0].value
              );
              this.updateLocalInvoiceObjects(Object.assign(o));
            },
            (err) => {
              console.log("approve fail:", err);
            }
          );
      }
    });
  }

  reject() {
    this.dialog.title = "Faturayı Reddet";
    this.dialog.message = "Faturayı Reddetmek İstiyor musunuz?";

    this.dialog.openWithPompt([
      { title: "Açıklama Giriniz", value: "", required: true },
    ]);
    try {
      this.dialog.statPrompt().then((promptsResp) => {
        if (promptsResp.stat) {
          console.log(Object.assign(promptsResp).prompts[0].value);
          this.loadingVisible = true;
          this.loadingMessage = "İşleniyor...";
          this.activeInvoice = this.selectedInovices[0];
          this.inoviceSrc
            .rejectInbound(
              this.activeInvoice.uuid,
              Object.assign(promptsResp).prompts[0].value
            )
            .toPromise()
            .then((o) => {
              this.refreshWithFilter();
              this.dialog.close();
              this.loadingVisible = false;
              this.addLog(
                "reject",
                this.activeInvoice,
                "fatura red",
                Object.assign(promptsResp).prompts[0].value
              );
              this.updateLocalInvoiceObjects(Object.assign(o));
            })
            .catch((x) => {
              this.loadingVisible = false;
              this.glb.notice(x, "error", "");
            });
        }
      });
    } catch (error) {
      this.loadingVisible = false;
      this.glb.notice(error, "error");
    }
  }
}
